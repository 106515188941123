<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none': detail }">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <transition name="fade">
          <PageLoading v-if="PageLoading"></PageLoading>
        </transition>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <!-- 操作template -->
          <template v-slot:uuid="{ item }">
            <a
              @click="GetDetail(item.uuid)"
              data-bs-toggle="tooltip"
              title="詳細內容"
              href="javascript:void(0)"
              class="me-2"
            >
              <MDBIcon icon="envelope-open-text" iconStyle="fas" class="text-primary" />
            </a>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  <EmailLogDetail v-model="detail" v-model:uuid="uuid"></EmailLogDetail>
</template>

<style scoped>
.sub-category {
  list-style: auto;
  text-align: start;
}
.sub-category li {
  border-bottom: 1px solid;
  width: fit-content;
}
.sub-div {
  display: inline-block;
  margin-right: 10px;
}
</style>

<script>
import { MDBContainer, MDBIcon, MDBCardBody, MDBCard } from "mdb-vue-ui-kit";
import DataTable from "@/components/Plugin/DataTable";
import Breadcrumbs from "@/components/Breadcrumbs";
import EmailLogDetail from "@/components/EmailLogDetail";
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from "@/components/Elements/PageLoading";
import { ref } from "vue";

export default {
  name: "EmailLog",
  components: {
    DataTable,
    MDBContainer,
    MDBIcon,
    Breadcrumbs,
    EmailLogDetail,
    MDBCardBody,
    MDBCard,
    PageLoading,
  },
  data() {
    return {
      detail: false,
      loading: true,
      uuid: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["created_at"],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      headers: [
        {
          text: this.$i18n.t("COMMON.SEQUENCE"),
          align: "start",
          sortable: false,
          value: "no",
        },
        { text: this.$i18n.t("EMAIL.LOG.TO"), value: "to" },
        { text: this.$i18n.t("EMAIL.LOG.SUBJECT"), value: "subject" },
        // { text: this.$i18n.t("EMAIL.LOG.CONTENT"), value: "content" },
        { text: this.$i18n.t("EMAIL.LOG.SENDTIME"), value: "created_at" },
        { text: this.$i18n.t("COMMON.OPERATE"), value: "uuid", sortable: false },
      ],
      desserts: [],
      total: 0,
      Common: CommonService,
      language: {
        items: i18nService.languages,
        selected: "",
      },
    };
  },
  setup() {
    const PageLoading = ref(true);
    return {
      PageLoading,
    };
  },
  created() {
    this.GetTable();
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true,
    },
  },
  provide() {
    return {
      GetTable: this.GetTable,
    };
  },
  methods: {
    GetTable() {
      return new Promise((resolve) => {
        this.loading = true;
        ApiService.query("/Admin/v1/email/log", {
          params: {
            current_page: this.options.page,
            pre_page: this.options.itemsPerPage,
          },
        }).then((response) => {
          if (response.status == 200) {
            this.desserts = CommonService.DataNoHandle(
              response.data.data.data,
              this.options.page,
              this.options.itemsPerPage
            );
            this.total = response.data.data.total;
            resolve(response);
          }
          this.loading = false;
        });
      });
    },
    GetDetail(uuid) {
      this.uuid = uuid;
    },
  },
  mounted() {
    this.GetTable().then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
