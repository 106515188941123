<template>
  <MDBCard class="m-3" :class="{ 'd-none': !Model }">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol md="12">
            <div class="card-box">
              <div class="panel-body">
                <div class="row">
                  <div class="col-6">
                    <div class="clearfix m-t-30">
                      <div class="float-right m-t-30">
                        <div class="m-t-10">
                          <strong>{{ $t("EMAIL.LOG.TO") }}: </strong>
                          {{ data.to }}
                        </div>
                        <div class="m-t-10">
                          <strong>{{ $t("EMAIL.LOG.SUBJECT") }}: </strong>
                          {{ data.subject }}
                        </div>
                        <div class="m-t-10">
                          <strong>{{ $t("EMAIL.LOG.CONTENT") }}: </strong>
                          <br />
                          <div v-html="data.content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="hidden-print">
                  <div class="float-right">
                    <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
.badge-info {
  color: #ffffff;
  background-color: #8950fc;
}
.badge-warning {
  color: #181c32;
  background-color: #ffa800;
}
.messageReplyContent * {
  white-space: normal;
  word-break: break-word;
}
</style>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
} from "mdb-vue-ui-kit";
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, ref } from "vue";

export default {
  name: "EmailLogDetail",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBCardBody,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: (val) => emit("update:modelValue", val),
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: (val) => emit("update:uuid", val),
    });
    const SubmitLoading = ref(false);
    const Common = CommonService;
    const EditUUid = ref("");
    const data = ref({});

    const NextDate = function (date, month) {
      let d = new Date(date);
      d.setMonth(d.getMonth() + month);
      return d.toLocaleString();
    };

    const Close = function () {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Model,
      SubmitLoading,
      FormUUid,
      Common,
      Close,
      EditUUid,
      data,
      NextDate,
    };
  },
  watch: {
    uuid: function (val) {
      if (val) {
        this.GetDetail();
      }
    },
  },
  methods: {
    GetDetail() {
      Promise.all([
        new Promise((resolve) => {
          ApiService.get("/Admin/v1/email/log", this.FormUUid).then((response) => {
            if (response.status == 200) {
              const responseData = response.data.data;
              this.data = responseData;
              this.data.content = this.htmlDecode(responseData.content);
              this.Model = true;
              resolve(response);
            }
          });
        }),
      ]);
    },
    htmlDecode(str) {
      var ele = document.createElement("div");
      ele.innerHTML = str;
      return ele.textContent || ele.innerText;
    },
  },
};
</script>
